<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  page: {
    title: "Nouveau service",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Nouveau service",
      items: [
        {
          text: "Hiérarchie",
          href: "/",
        },
        {
          text: "Services",
          href: "/",
        },
        {
          text: "Nouveau",
          active: true,
        },
      ],
      servForm: {
        servName: "",
        servAc: "",
        servDept: "",
        servParentService: "",
      },
      departments: [],
      services: [],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    servForm: {
      servName: { required, maxLength: maxLength(64) },
      servAc: { required, maxLength: maxLength(64) },
      servDept: { required }
    },
  },
  methods: {
    fetchDeptsList() {
      this.$http
        .post("/hierarchy/departments/list")
        .then((res) => (this.departments = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    fetchServicesList() {
      this.$http
        .post("/hierarchy/services/list")
        .then((res) => (this.services = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    formSubmit() {
      this.submitted = true;
      this.$v.$touch()
      if(!this.$v.$invalid){
        var router = this.$router;
        this.$http
          .post("/hierarchy/services/store", this.servForm)
  
          .then((res) => {
            var status = res.data.original.status;
            switch (status) {
              case 200:
                  this.$toast.success(res.data.original.msg);
                  router.push({ name: "hierarchy.services.index" });
                break;
  
              case 500:
                  this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            this.$toast.success(error.message);
          })
          .finally(() => {});
      }
    },
  },

  mounted() {
    this.fetchDeptsList();
    this.fetchServicesList();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="deptName">Désignation</label>
                    <input
                      id="deptName"
                      v-model="servForm.servName"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value="Mark"
                      :class="{
                        'is-invalid': submitted && $v.servForm.servName.$error,
                      }"
                    />
                    <div v-if="submitted && $v.servForm.servName.$error" class="invalid-feedback">
                      <span v-if="!$v.servForm.servName.required">Le nom de service est obligatoire.</span>
                      <span v-if="!$v.servForm.servName.maxLength" >La taille du champs est limitée a 64 charactères.</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom03">Département</label>
                    <select
                      id="projectname"
                      v-model="servForm.servDept"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted && $v.servForm.servDept.$error,
                      }"
                      placeholder=""
                    >
                      <option value=""></option>
                      <option
                        v-for="department in departments"
                        v-bind:value="department.id"
                        v-bind:key="department.id"
                      >
                        {{ department.name }}
                      </option>
                    </select>
                    <div
                      v-if="submitted && $v.servForm.servDept.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.servForm.servDept.required"
                        >Le Département est obligatoire.</span
                      >

                    </div>
                  </div>
                </div>
                
              </div>
              <div class="row">
                

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom03">Service Parent</label>
                    <select
                      id="projectname"
                      v-model="servForm.servParentService"
                      type="text"
                      class="form-control"
                      placeholder=""
                    >
                      <option value=""></option>
                      <option
                        v-for="service in services"
                        v-bind:value="service.id"
                        v-bind:key="service.id"
                      >
                        {{ service.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom03">Compte analytique</label>
                    <input
                      id="validationCustom03"
                      v-model="servForm.servAc"
                      type="text"
                      class="form-control"
                      placeholder=""
                      :class="{
                        'is-invalid': submitted && $v.servForm.servAc.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.servForm.servAc.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.servForm.servAc.required"
                        >Le compte analytique est obligatoire.</span
                      >
                      <span v-if="!$v.servForm.servAc.maxLength" >La taille du champs est limitée a 64 charactères.</span>
                    </div>
                  </div>
                </div>
              </div>

              <button
                class="btn btn-primary"
                type="submit"
              >
                Enregistrer
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
